import React, {useState, useEffect} from 'react';
import {Menu, MenuItem, Divider} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import styled from 'styled-components';

import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { bindActionCreators } from 'redux';

const StyledMenu = styled(({ className, ...props }) => (
    <Menu {...props} classes={{ paper: className }} />
  ))`
    box-shadow: 5px 5px 10px -8px rgba(0,0,0,0.20) !important;
    border: 1px solid #e6ebf1;
  `;


const SelectInterval = (props) => {
    const {
        actions,
        intervals,
        interval
      } = props;
    
      const allIntervals = typeof intervals === 'string' ? JSON.parse(intervals) : intervals;

      const current_interval = interval ||  "year";
      
      const [anchorEl, setAnchorEl] = useState(false);
    
      const handleClick = (event) => setAnchorEl(event?.currentTarget);
    
      const handleClose = () => setAnchorEl(null);
    
      const handleSelect = async (option) => {
        await actions.setInterval(option);
        handleClose();
      }

      const intervalSelect = (param) =>  {
        if (param === 'month') {
          return 'Mensual'
        } else if (param === 'year') {
          return 'Anual'
        } else if (param === 'week') {
          return 'Semanal'
        } else if (param === 'day') {
          return 'Diario'
        } else if (param === 'bimonthly') {
          return 'Bimestral'
        } else if (param === 'quarterly') {
          return 'Trimestral'
        } else if (param === 'semiannual') {
          return 'Semestral'
        } else {
          return ''
        }
      }

    return (
        <>
        <button
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          className="p-0 relative" 
          onClick={(e) => handleClick(e)}
        >
          <span className="text-gray-800 text-sm cursor-pointer text-center flex items-center">
            {intervalSelect(current_interval)} <KeyboardArrowDownIcon className="text-xs"/>
          </span>
        </button>
        <StyledMenu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{ left: '1rem' }}
        >
          {allIntervals?.map((option, index) => (
            <MenuItem
              key={index}
              className="text-sm text-gray-800"
              onClick={() => handleSelect(option)}
            >
              <span className="flex items-center">
                {intervalSelect(option)}
              </span>
            </MenuItem>
          ))}
        </StyledMenu>
        </>
    )
}

function mapStateToProps(state, props) {
  const interval = state.affiliation.get('interval');
  const intervals = state.affiliation.get('intervals');
  return {
    interval,
    intervals,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(SelectInterval)

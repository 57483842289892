import React from 'react';
import Link from 'next/link';

const serverUrlImages = process.env.REACT_APP_SERVER_URL_IMAGES;
const banner1 = `${serverUrlImages}/images/servicios-telemedicina.jpg`;
const banner2 = `${serverUrlImages}/images/servicios-medicamentos.jpg`;
const banner3 = `${serverUrlImages}/images/VEservicios-hospitalizacion-cirugia.png`;
const banner4 = `${serverUrlImages}/VE/examenes-laboratorio.png`;
const banner5 = `${serverUrlImages}/VE/segunda-opinion-medica.png`;
const banner6 = `${serverUrlImages}/VE/lectura-examenes-medicos.png`;
const banner7 = `${serverUrlImages}/VE/familia-servicio-covid.png`;


export const responsive = {
	xl: {
		breakpoint: { max: 5000, min: 1920 },
		items: 3
	},
	lg: {
		breakpoint: { max: 1919, min: 1280 },
		items: 3
	},
	md: {
		breakpoint: { max: 1279, min: 960 },
		items: 2
	},
	sm: {
		breakpoint: { max: 959, min: 600 },
		items: 2
	},
	xs: {
		breakpoint: { max: 599, min: 0 },
		items: 1
	}
};

export const data = [
    {
        img: {
            src: banner1,
            alt: 'Médico de cabecera virtual en Venezuela',
            name: 'medico-cabecera-virtual-venezuela.jpg',
        },
        title: 'Médico de cabecera virtual',
        subtitle: <span>Consultas médicas online o telefónicas disponibles las 24 horas a través de nuestra <span className="text-pink-500 font-bold">App asistensi</span>. <br />Atención inmediata por médicos que te acompañarán durante la emergencia.</span>,
    },
    {
        img: {
            src: banner2,
            alt: 'Envío de medicamentos en Venezuela',
            name: 'servicios-medicamentos.jpg',
        },
        title: 'Envío de medicamentos',
        subtitle: <span>Entrega inmediata y sin costos adicionales de medicamentos para enfermedades agudas e inventario crítico, en clínicas que trabajan con <span className="text-pink-500 font-bold">asistensi</span>.</span>
    },
    {
        img: {
            src: banner3,
            alt: 'Cobertura de hospitalización y cirugía de emergencia amparada por Mercantil Seguros',
            name: 'VEservicios-hospitalizacion-cirugia.png',
        },
        title: 'Cobertura de hospitalización y cirugía de emergencia amparada por Mercantil Seguros',
        subtitle: 'Cobertura para hospitalización y cirugía de emergencia en clínicas privadas amparada por Mercantil Seguros hasta por USD 10,000 por patología, por año.'
    },
    {
        img: {
            src: banner4,
            alt: 'Exámenes de laboratorio en Venezuela',
            name: 'examenes-laboratorio.png',
        },
        title: 'Exámenes de laboratorio',
        subtitle: 'Para poder recibir un mejor diagnóstico y tratamiento médico, cuentas con dos pruebas de laboratorios clínicos al año.'
    },
    {
        img: {
            src: banner5,
            alt: 'Segunda opinión médica en Venezuela',
            name: 'segunda-opinion-medica.png',
        },
        title: 'Segunda opinión médica',
        subtitle: 'Segunda opinión médica sobre un diagnóstico o tratamiento indicado en una consulta médica anterior.'
    },
    {
        img: {
            src: banner6,
            alt: 'Lectura de exámenes médicos en Venezuela',
            name: 'lectura-examenes-medicos.png',
        },
        title: 'Lectura de exámenes médicos',
        subtitle: 'Apoyo de nuestros médicos para la lectura de exámenes paraclínicos.'
    },
    {
        img: {
            src: banner7,
            alt: 'Servicios COVID-19 en Venezuela',
            name: 'familia-servicio-covid.png',
        },
        title: 'Servicios COVID-19',
        subtitle: 'Acompañamiento médico remoto continuo desde el momento del primer síntoma y cobertura de hospitalización en clínicas para casos más graves.'
    }
];

export const Title = () => (
    <h2 className="text-purple-500 text-center font-semibold text-2xl md:text-4xl leading-snug mb-4">
        ¿Qué servicios de salud ofrecemos?
    </h2>
);

export const PrimaryComponent = ({ item }) => (
    <>
        <style global jsx>
            {`
                .transform-scale:hover .scale-children {
                    transform: scale(1) !important;
                    animation-name: fadeIn !important;
                    animation-fill-mode: both !important;
                    -webkit-animation-duration: 1s !important;
                    animation-duration: 1s !important;
                    -webkit-animation-fill-mode: both !important;
                }
                .min-size-arrow {
                    min-width: 24px;
                    min-height: 24px;
                }
                .react-multiple-carousel__arrow {
                    z-index: 10 !important;
                }
            `}
        </style>
        <div className="my-4 mx-3 relative transition duration-300 ease-in-out min-h-93 rounded pointer-events-none select-none">
            <img loading="lazy" src={item.img?.src} className="w-full h-auto rounded-xl pointer-events-none select-none" name={item.img?.name} alt={item.img?.alt} />
            <p className="text-xl font-bold leading-snug text-gray-800 mt-4 pointer-events-none select-none">
                {item.title}
            </p>
            <p
                className="text-base text-gray-800 leading-relaxed mt-4 pointer-events-none select-none"
            >
                {item.subtitle}
            </p>
        </div>
    </>
);

export const SecondaryComponent = () => (
    <div className="flex w-full justify-center mt-5 mb-0">
        <Link href="/benefits" as="/beneficios">
            <button className="white-btn">Ver más servicios de salud</button>
        </Link>
    </div>
);
import React, { useState } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";

import ReactTelInput from "react-telephone-input";
import { TextInput } from "components/commons";
import { SnackBar, SpinnerV } from "components/widgets";
import Flags from "../../assets/images/flags.png";

import PhoneIcon from "@material-ui/icons/Phone";
import CloseIcon from "@material-ui/icons/Close";

const LeadForm = ({ showForm, setShowForm, actions }) => {
  const initalLead = {
    first_name: "",
    phone_one: "",
    email: "",
  };
  const [lead, setLead] = useState(initalLead);
  const [formErrors, setFormErrors] = useState({});
  const [alert, setAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [spinnerScreen, setSpinnerScreen] = useState(false);
  const [step, setStep] = useState(1);

  const validateInput = (name, value) => {
    let validate = false;
    switch (name) {
      case "phone_one":
        validate = /^([0-9()+ -]{10,})$/.test(value);
        !validate
          ? (formErrors.phone_one =
              "Teléfono inválido. Ejemplo: (+58) 416 765-432")
          : delete formErrors.phone_one;
        break;
      case "first_name":
        validate = /^([0-9a-zA-ZñÑáéíóúÁÉÍÓÚüÜ ,._-]{5,})$/.test(value);
        !validate
          ? (formErrors.first_name = "Nombre inválido.")
          : delete formErrors.first_name;
        break;
      case "email":
        validate = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(value);
        !validate
          ? (formErrors.email = "Correo electrónico inválido.")
          : delete formErrors.email;
        break;
    }
    setFormErrors({ ...formErrors });
  };

  const handleInput = (event, telNumber) => {
    if (event === "phone_one") {
      lead.phone_one = telNumber;
      validateInput("phone_one", telNumber);
    } else {
      let { name, value } = event.target;
      lead[name] = value;
      validateInput(name, value);
    }
    setLead({ ...lead });
  };

  const handleSubmit = async () => {
    Object.keys(lead).forEach((k) => validateInput(k, lead[k]));
    if (Object.keys(formErrors).length === 0) {
      setSpinnerScreen(true);
      const response = await actions.createNewLead({
        ...lead,
        origin: "CALL-ME-AT-VE",
        marketing_campaign: "CALL-ME-AT-VE",
      });
      if (response.success) {
        setShowForm(false);
        setLead(initalLead);
        setStep(1);
      }
      setSpinnerScreen(response.spinnerScreen);
      setMessageAlert(response.messageAlert);
      setAlert(response.openAlert);
    }
  };

  const handleStep = () => {
    Object.keys(lead).forEach((k) => {
      if (k !== "first_name") {
        validateInput(k, lead[k]);
      }
    });
    if (Object.keys(formErrors).length === 0) setStep(2);
  };

  return (
    <>
      {spinnerScreen && <SpinnerV />}
      <div
        className={`fixed hidden max-w-xs bg-white border-2 border-gray-300 p-6 pt-3 rounded-md shadow-md form ${
          showForm ? "md:block" : ""
        }`}
        style={{ zIndex: 21, top: "50%", right: "1rem" }}
      >
        <style global jsx>
          {`
            .contact-form.telInput,
            .contact-form.telInputError {
              width: 100% !important;
            }
            .contact-form.telInput::before,
            .contact-form.telInputError::before {
              content: "¿A qué número de teléfono? *" !important;
            }

            .form {
              min-width: 20rem;
              min-height: 22rem;
            }
          `}
        </style>
        <span
          className="text-pink-500 font-bold w-full flex justify-end cursor-pointer"
          onClick={() => setShowForm(false)}
        >
          <CloseIcon className="text-pink-500" />
        </span>
        <div className="text-pink-500 font-bold">
          <span>Te contactamos gratis </span>
        </div>
        {step == 1 && (
          <>
            <ReactTelInput
              required={true}
              onChange={(telNumber) => handleInput("phone_one", telNumber)}
              value={lead.phone_one}
              defaultCountry="ve"
              flagsImagePath={Flags}
              className={"telInput contact-form w-full mx-0"}
              className={
                lead.phone_one && !formErrors?.phone_one
                  ? "telInput contact-form w-full mx-0"
                  : (!lead.phone_one && formErrors?.phone_one) ||
                    formErrors?.phone_one
                  ? "telInputError contact-form w-full mx-0"
                  : "telInput contact-form w-full mx-0"
              }
            />
            <TextInput
              name="email"
              type="email"
              label="¿A qué correo electrónico?"
              placeholder="Introduce tu correo"
              value={lead.email}
              helperText={formErrors?.email}
              errors={formErrors?.email}
              addClass="wInputFull mx-0"
              onChange={handleInput}
              required={true}
            />
          </>
        )}

        {step === 2 && (
          <div className="mt-5 mb-8 min-w-full">
            <TextInput
              name="first_name"
              label="¿Por quién preguntamos?"
              placeholder="Nombre"
              value={lead.first_name}
              helperText={formErrors?.first_name}
              errors={formErrors?.first_name}
              addClass="wInputFull mx-0"
              onChange={handleInput}
              required={true}
            />
          </div>
        )}

        {step == 1 && (
          <button
            className="white-button-nav flex justify-center items-center min-w-full"
            onClick={handleStep}
          >
            Quiero que me llamen
          </button>
        )}
        {step == 2 && (
          <button
            className="white-button-nav flex justify-center items-center min-w-full"
            onClick={handleSubmit}
          >
            Enviar
          </button>
        )}
        <a
          className="secondary-button-nav flex justify-center items-center min-w-full mt-3"
          href="tel:+582128221260"
          rel="noopener noreferrer"
        >
          <PhoneIcon className="text-base text-pink-500 mr-2" />
          +58 (212) 822 12 60
        </a>
      </div>
      <SnackBar
        openAlert={alert}
        messageAlert={messageAlert}
        handleClose={() => setAlert(false)}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(LeadForm);

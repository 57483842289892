import React from "react";
import {
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContent,
	TextField,
} from "@material-ui/core";
import useInterval from "components/hooks/useInterval";

const STATUS = {
	STARTED: "Started",
	STOPPED: "Stopped",
};

function DialogConfirmPhone({
	open,
	handleClose,
	verifiedNumber,
	handleInputCode,
	spinnerScreen,
	handleVerifyCode,
	countDown = 60,
	handleResendCode,
	title = "Ingresa el código que te hemos enviado por SMS",
	placeholder = "000000",
}) {
	const [disabledResend, setDisabledResend] = React.useState(true);
	const [statusTimer, setStatusTimer] = React.useState(STATUS.STOPPED);
	const [secondsRemaining, setSecondsRemaining] = React.useState(countDown);

	React.useEffect(() => {
		handleStartcountDown();
	}, [open]);

	useInterval(
		() => {
			if (secondsRemaining > 0) {
				setSecondsRemaining(secondsRemaining - 1);
			} else {
				setDisabledResend(false);
				setStatusTimer(STATUS.STOPPED);
			}
		},
		statusTimer === STATUS.STARTED ? 1000 : null
	);

	const handleStartcountDown = React.useCallback(() => {
		setSecondsRemaining(countDown);
		setDisabledResend(true);
		setStatusTimer(STATUS.STARTED);
	}, []);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			disableBackdropClick={true}
			disableEscapeKeyDown={true}
		>
			<style global jsx>
				{`
					.wInputFull.nm {
						margin: 20px 0 10px !important;
					}
				`}
			</style>
			<DialogTitle>
				<span className="font-bold text-gray-800">{title}</span>
			</DialogTitle>

			<DialogContent>
				<div className="w-full ">
					<TextField
						onKeyPress={(e) => {
							if (e.key === "Enter") {
								handleVerifyCode(e);
							}
						}}
						name="verifiedNumber"
						placeholder={placeholder}
						error={true}
						value={verifiedNumber}
						onChange={handleInputCode}
						variant="outlined"
						className="wInputFull"
						InputProps={{
							classes: { notchedOutline: "cssInputSelect" },
						}}
					/>
					<div className="px-4">
						{disabledResend ? (
							<span>
								Espere <b className="text-pink-500">{secondsRemaining}s</b> para
								reenviar otro código.
							</span>
						) : (
							<span
								onClick={() => {
									handleResendCode(handleStartcountDown);
								}}
								className="block text-pink-500 underline cursor-pointer"
							>
								Reenviar código.
							</span>
						)}
					</div>
				</div>
			</DialogContent>

			<DialogActions>
				<div className="px-4 py-4 grid gap-x-3 grid-flow-col">
					<button
						onClick={handleClose}
						className="secondary-button-nav max-w-small "
					>
						Cancelar
					</button>
					<button
						onClick={(e) => handleVerifyCode(e)}
						disabled={
							!verifiedNumber || verifiedNumber.length < 4
								? true
								: false || spinnerScreen
						}
						className="white-button-nav max-w-small"
					>
						Enviar
					</button>
				</div>
			</DialogActions>
		</Dialog>
	);
}

export default DialogConfirmPhone;

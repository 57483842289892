import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  TablePagination,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import SnackBar from '../../components/widgets/SnackBar';
import { chipClientStatus } from 'components/utils/ClientStatus';
import { chipPaymentStatus } from 'components/utils/Payments';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 0,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(5),
  },
  progress: {
    margin: '0px',
    color: '#707070',
  },
});

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class BeneficiariesTable extends Component {

  state = {
    page: 0,
    rowsPerPage: 5,
    openAlert: false,
    messageAlert: '',
    data: this.props.customers || [],
    order: 'asc',
    orderBy: '',
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({ data: nextProps.customers });
  }

  handleChangePage = (event, page) => {
    this.props.actions.searchPages({ page });
  };

  handleChangeRowsPerPage = event => {
    this.props.actions.searchRowsPerPage({ page: 0, rowsPerPage: event.target.value });
  };

  handleClose = () => {
    this.setState({ openAlert: false });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  componentDidMount() {
    const { renovations, customers } = this.props;
    if(renovations) this.props.actions.searchRowsPerPage({ page: 0, rowsPerPage: customers?.length })
  }

  render() {
    const { classes, rowsPerPage, page, renovations, paymentStatus } = this.props;
    const { order, orderBy, data } = this.state;

    return (
      <Paper className={`customer-paper ${classes.root}`}>
        <style>
          {`
            .customer-paper {
              box-shadow: none !important;
            }
            .customer-paper .customer-table .table-header th {
              font-weight: 600 !important; font-size: 12px !important;
              color: rgba(0, 0, 0, 0.54) !important;
            }
            .customer-paper .customer-table tbody tr:nth-child(odd) {
              background-color: #fafafa !important;
            }
            .customer-paper .customer-table tbody td {
              font-size: 14px !important;
            }
            .customer-paper .customer-table tbody td:nth-child(1) { border-left: 1px solid #e6ebf1 }
            .customer-paper .customer-table tbody td:last-child { border-right: 1px solid #e6ebf1 }
          `}
        </style>
        <Table className={`customer-table ${classes.table}`}>
          <TableHead>
            <TableRow className="table-header text-sm">
              <TableCell
                sortDirection={orderBy === 'first_name' ? order : false}
                align='left'
              >
                <TableSortLabel
                  active={orderBy === 'first_name'}
                  direction={order}
                  onClick={(e) => this.handleRequestSort(e, 'first_name')}
                >
                  Nombres y Apellidos
                </TableSortLabel>
              </TableCell>

              <TableCell
                sortDirection={orderBy === 'dni' ? order : false}
                align='left'
              >
                <TableSortLabel
                  active={orderBy === 'dni'}
                  direction={order}
                  onClick={(e) => this.handleRequestSort(e, 'dni')}
                >
                  Número de Documento
                </TableSortLabel>
              </TableCell>

              <TableCell
                sortDirection={orderBy === 'kinship' ? order : false}
                align='left'
              >
                <TableSortLabel
                  active={orderBy === 'kinship'}
                  direction={order}
                  onClick={(e) => this.handleRequestSort(e, 'kinship')}
                >
                  Parentesco
                </TableSortLabel>
              </TableCell>

              { renovations && <TableCell align='left'>Plan</TableCell> }

              {
                !renovations &&
                <TableCell
                  sortDirection={orderBy === 'end_date' ? order : false}
                  align='left'
                >
                  <TableSortLabel
                    active={orderBy === 'end_date'}
                    direction={order}
                    onClick={(e) => this.handleRequestSort(e, 'end_date')}
                  >
                    Expiración de cobertura
                  </TableSortLabel>
                </TableCell>
              }

              {
                !renovations &&
                <TableCell
                  sortDirection={orderBy === 'status' ? order : false}
                  align='left'
                >
                  <TableSortLabel
                    active={orderBy === 'status'}
                    direction={order}
                    onClick={(e) => this.handleRequestSort(e, 'status')}
                  >
                    Estatus
                  </TableSortLabel>
                </TableCell>
              }
              <TableCell align='left'>
                Soporte HbA1c
              </TableCell>

              {
                this.props.is_profile === true && !renovations &&
                <TableCell align='left'>
                  Acciones
                </TableCell>
              }

            </TableRow>
          </TableHead>
          <TableBody className="table-cells text-sm">
            {
              Array.isArray(data) &&
              stableSort(data, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.first_name} {row.last_name}</TableCell>
                <TableCell>{row.dni || '-'}</TableCell>
                <TableCell>{row.kinship && row.kinship !=='TITULAR' ? row.kinship  :"-"}</TableCell>                
                <TableCell>
                  {
                    renovations
                    ? row?.product_type?.name || ''
                    : new Date(row.end_date).toLocaleDateString()
                  }
                </TableCell>
                <TableCell>
                  {
                    row.is_client === true && row?.payment_id?.status !== 'not-complete' && <>{chipPaymentStatus(row?.payment_id?.status || paymentStatus)}</>
                  }
                  {
                    ((row.is_client === true && row?.payment_id?.status === 'not-complete') || 
                    ((row.is_client === false &&
                      (row.requireMedicalVerification === false ||
                        (row.requireMedicalVerification === true && (row.medicalVerification === 'approved' || row.medicalVerification === 'rejected'))
                      )
                    ) && !renovations)) &&
                    <button className="text-pink-500 font-bold text-small tracking-extra" onClick={() => this.props.goToAfiliate()}>CONTRATARLE SEGURO {'>'} </button>
                  }
                  {
                    (row.is_client === false && row.requireMedicalVerification === true && (!row.medicalVerification || (row.medicalVerification && row.medicalVerification === 'pending'))) && !renovations &&
                    <>{chipClientStatus('requireMedicalVerification')}</>
                  }
                  {
                    (row.is_client === true && row.requireMedicalVerification === true && row?.payment_id) &&
                    <>{chipClientStatus('requireMedicalVerification')}</>
                  }
                </TableCell>

                <TableCell>
                  {!row.requireSupportVerification ? (
                    <span className="text-gray-800">-</span>
                  ) : (
                    row.requireSupportVerification && !row.medical_exam ? (
                      <>
                        {/*<span className="text-gray-800">Archivo no cargado</span>*/}
                        <WarningRoundedIcon
                          className="text-orange-400 hba1c-icon cursor-pointer"
                          onClick={() => this.props.handleUpload(row)}
                        />
                      </>
                    ) : (
                      <a href={`${process.env.REACT_APP_BASE_PATH_FILES}${row.medical_exam}`} target="_blank" className="text-purple-500">
                        Ver soporte
                      </a>
                      // <span className="cursor-pointer text-gray-800 underline">Archivo cargado</span>
                    )
                  )}
                </TableCell>

                {
                  this.props.is_profile === true && !renovations &&
                  <TableCell>
                    <Tooltip title="Editar">
                      <IconButton
                        key="editar"
                        aria-label="editar"
                        onClick={() => this.props.showEditDialog(row,((rowsPerPage * page) + index))}
                        >
                        <EditIcon className="text-gray-600"/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                      <IconButton
                        key="Eliminar"
                        aria-label="Eliminar"
                        onClick={() => this.props.deleteMember(((rowsPerPage * page) + index))}
                        >
                        <DeleteIcon className="text-gray-600"/>
                      </IconButton>
                    </Tooltip>
                    {(row.requireSupportVerification && !row.medical_exam) && (
                      <Tooltip title="Cargar archivo">
                        <IconButton
                          key="cargar"
                          aria-label="cargar"
                          onClick={() => this.props.handleUpload(row)}
                        >
                          <CloudUploadIcon className="text-gray-600"/>
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {
          ((Array.isArray(this.state.data) && this.state.data.length > 5) && !renovations) &&
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20]}
            component="div"
            rowsPerPage={this.props.rowsPerPage}
            page={this.props.page}
            count={this.state.data.length}
            backIconButtonProps={{
              'aria-label': 'Siguiente',
            }}
            nextIconButtonProps={{
              'aria-label': 'Anterior',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        }
        <SnackBar openAlert={this.state.openAlert} messageAlert={this.state.messageAlert} handleClose={this.handleClose}/>
      </Paper>
    );
  }
}

BeneficiariesTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, props) {
  const page = state.search.get('page');
  const rowsPerPage = state.search.get('rowsPerPage');

  return {
    page,
    rowsPerPage,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(BeneficiariesTable));

import React from 'react';

export const data = [
    {
        icon: '/images/newpapers/forbes.svg',
        text: 'Logo forbes',
        name: 'forbes.svg',
        width: 80
    },
    {
        icon: '/images/newpapers/eluniversal.svg',
        text: 'Logo de el universal',
        name: 'eluniversal.svg',
        width: 120
    },
    {
        icon: '/images/newpapers/talcual.svg',
        text: 'Logo tal cual',
        name: 'talcual.svg',
        width: 70
    },
    {
        icon: '/images/newpapers/ultimas-noticias.svg',
        text: 'Logo ultimas noticias',
        name: 'ultimas-noticias.svg',
        width: 140
    }
];

export const PrimaryComponent = ({ item }) => (
    <>
        <style>
            {`
                #__next > div > div > div > div:nth-child(4) > div > div > ul {
                    align-items: center;
                }
            `}
        </style>
        <div className="px-5 flex justify-start items-center flex-col w-full">
            <div className="flex items-center justify-center pointer-events-none">
                <img name={item.name} src={item.icon} alt={item.text} width={item.width || null} />
            </div>
        </div>
    </>
);
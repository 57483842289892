import React, { Component, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { isArray } from 'util';

import {
  TextField,
  MenuItem,
  Radio,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Tooltip,
  CircularProgress
} from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ReactTelInput from 'react-telephone-input';
import Flags from '../../assets/images/flags.png';
import CountryList from '../utils/CountryList';
import { SnackBar } from '../widgets';
import Validations from '../utils/Validations';
import FileWithIconInput from '../commons/FileWithIconInput';
import {
  TextInput,
  SelectSimpleOutlined,
  DateInput,
  SelectCurrency
} from 'components/commons';
import CityList from 'components/utils/CityList';
import SelectInterval from '../commons/SelectInterval';
import { _calculateAge } from '../utils/CalculateAge';

const HeadlineForm = (props) => {
  const { twoColumns = false } = props;
  const router = useRouter();

  const [alert, setAlert] = useState({
    openAlert: false,
    messageAlert: ''
  });

  const [isChangeEmail, setIsChangedEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showInfo, setShowInfo] = useState('');

  const handleClose = () => setAlert({ ...alert, openAlert: false });

  useEffect(() => {
    const getInitialData = async () => {
      const {
        saveState,
        readOnlyPlans,
        is_profile,
        getProductsByFrequency,
        titular
      } = props;
      let titularData = { ...titular };

      if (!titularData.formErrors) {
        if (
          (titular.include_titular === false || !titularData.include_titular) &&
          !titularData.city
        )
          titularData.city = '';
        titularData.formErrors = {};
        saveState('titular', titularData);
      }
      if (
        titularData?.include_titular === true &&
        !readOnlyPlans &&
        titularData.age &&
        (!titularData.products || titularData.products?.length <= 0) &&
        is_profile !== true
      ) {
        const result = await getProductsByFrequency({ age: titular.age });
        if (result.products) titularData.products = result.products;
        titular.kinship = 'TITULAR';
        saveState('titular', titularData);
      }

      if (titularData.product_id) {
        const productType =
          titularData.product_type &&
          typeof titularData.product_type === 'string'
            ? JSON.parse(titularData.product_type)
            : titularData.product_type;
        if (titular?.products?.length > 0) {
          const findProduct = titular?.products?.find(
            (product) => product._id === productType._id
          );
          titularData.product_type = productType;
          titularData.questions = findProduct?.questions;
        }

        saveState('titular', titularData);
      }

      setShowInfo('');
    };
    getInitialData();
  }, []);

  //Get Products when titular has birth_date
  useEffect(() => {
    let titularData = { ...props.titular };
    const getProducts = async () => {
      const age = _calculateAge(props.titular.birth_date);
      titularData.age = age;
      const result = await props.getProductsByFrequency({ age });
      if (result.products) titularData.products = result.products;
      props.saveState('titular', titularData);
    };
    if (
      props.titular.include_titular &&
      props.titular.birth_date &&
      (!props.titular.products || props.titular.products.length === 0)
    ) {
      getProducts();
    }
  }, [props.titular.include_titular]);

  const handleInput = async (event) => {
    const {
			is_profile,
			saveState,
			calculations,
			readOnlyPlans,
			titular,
			paymentHeadlineForm,
			payment,
			useTitularData,
		} = props;
		const { name, value } = event.target;

    if (name === 'email') {
      setIsChangedEmail(props.originalEmail !== titular.email ? true : false);
    }

    if (name === "dni" && paymentHeadlineForm && useTitularData) {
			payment.dni = value;
			saveState("payment", payment);
		}

		if (
			(name === "city") &&
			paymentHeadlineForm &&
			useTitularData
		) {
			payment.city = value;
			payment.state = value;
			saveState("payment", payment);
		}

    saveState('titular', { ...props.titular, [name]: value });
    setShowInfo('');
    Validations.validateFieldHeadLine(
      { ...props.titular, [name]: value },
      saveState,
      name,
      value
    );
    // if(props.titular.include_titular === true && is_profile !== true && !readOnlyPlans) calculations();
  };

  const handleInputFile = (event) => {
    const { saveState, is_profile, readOnlyPlans, calculations } = props;
    const { name, files } = event.target;
    const file = files[0];
    const data = {
      body: '',
      type: file.type,
      extension: file.name.split('.').pop()
    };
    const reader = new FileReader();
    reader.onloadend = function () {
      data.body = reader.result;
    };
    saveState('titular', { ...props.titular, [name]: file, doc_dni: data });
    setShowInfo('');
    Validations.validateFieldHeadLine(
      { ...props.titular, [name]: file, doc_dni: data },
      saveState,
      'doc_dni',
      file
    );
    // if(titular.include_titular === true && is_profile !== true && !readOnlyPlans) calculations();
    return reader.readAsDataURL(file);
  };

  const handleInputCountry = (event) => {
    const { titular, saveState, payment, paymentHeadlineForm, activateEuro, useTitularData, modeAdmin, is_profile } = props;
    const { name, value } = event.target;

    if(value === "España" && paymentHeadlineForm){
      activateEuro()
    }
    if(payment && paymentHeadlineForm && useTitularData){
      payment.country = value;
      payment.zip_zone = "";
      saveState("payment", payment);
    }

    if(payment && !titular.include_titular){
      payment.acceptTerms = false;
      payment.DIP = false;
      payment.oath_statement = false;
      saveState("payment", payment);
    }

    saveState('titular', {
      ...titular,
      [name]: value,
      city: '',
      state: '',
      address: ''
    });
    setShowInfo('');
    Validations.validateFieldHeadLine(
      { ...titular, [name]: value, city: '', address: '' },
      saveState,
      name,
      value
    );
  };

  const handlePhone = (name, value) => {
    const {payment, saveState, titular, paymentHeadlineForm, useTitularData} = props;

    saveState('titular', { ...titular, [name]: value });
    setShowInfo('');

    if(paymentHeadlineForm && useTitularData){
      payment[name] = value;
      saveState("payment", payment);
    }
    
    Validations.validateFieldHeadLine(
      { ...props.titular, [name]: value },
      props.saveState,
      name,
      value
    );
  };

  const handleInputSex = async (event) => {
    let { name, value } = event.target;
    props.saveState('titular', {
      ...props.titular,
      [name]: value,
      conditions_pregnant: false
    });
    setShowInfo('');
    Validations.validateFieldHeadLine(
      { ...props.titular, [name]: value, conditions_pregnant: false },
      props.saveState,
      name,
      value
    );
  };

  const handleCheckbox = (event, param) => {
    const { name, checked } = event.target;
    props.saveState('titular', {
      ...props.titular,
      [name]: name === 'promo_code' ? param : checked
    });
    setShowInfo('');
    Validations.validateFieldHeadLine(
      { ...props.titular, [name]: name === 'promo_code' ? param : checked },
      props.saveState,
      name,
      checked
    );
  };

  const handleCheckboxAssistedSelling = (event, param) => {
    const { name, checked } = event.target;
    props.saveState('titular', {
      ...props.titular,
      [name]: name === 'assisted_selling' ? param : checked
    });
    setShowInfo('');
    Validations.validateFieldHeadLine(
      {
        ...props.titular,
        [name]: name === 'assisted_selling' ? param : checked
      },
      props.saveState,
      name,
      checked
    );
  };

  const handleBirthDate = async (date) => {
    setLoading(true);
    const {
      saveState,
      is_profile,
      readOnlyPlans,
      getProductsByFrequency,
      titular
    } = props;
    let titularData = { ...titular };
    titularData.birth_date = date;
    // await saveState("titular", { ...titular, birth_date: date });
    if (date && date instanceof Date && !isNaN(date.valueOf())) {
      if (titular?.formErrors?.birth_date)
        delete titular?.formErrors?.birth_date; //Mutate State Directly to delete the error ?
      const age = _calculateAge(date);
      titularData.age = age;
      if (
        titular.include_titular === true &&
        is_profile !== true &&
        !readOnlyPlans
      ) {
        const result = await getProductsByFrequency({ age });
        if (result.products) {
          titularData.products = result.products;
          // await saveState("titular", titularData);
        }
        if (result.products && titular.product_id) {
          const productType = result.products.find(
            (p) => titular.product_id === p._id
          );
          titularData.product_id = productType._id;
          titularData.product_type = productType;
          titularData.product_price = productType.product_price;
          titularData.total = productType.product_price;
        }
        if (result.success === false) {
          setAlert({
            openAlert: result.openAlert,
            messageAlert: result.messageAlert
          });
        }
        saveState('titular', titularData);
        setShowInfo('');
      }
    }
    Validations.validateFieldHeadLine(
      titularData,
      saveState,
      'birth_date',
      date
    );
    // if(titular.include_titular === true && is_profile !== true && !readOnlyPlans) calculations();
    setLoading(false);
  };

  const handleSelectPlan = async (product) => {
    let titularData = { ...props.titular };
    titularData.product_id = product._id;
    titularData.product_type = product;
    titularData.product_price = product.product_price;
    titularData.total = product.product_price;
    props.saveState('titular', { ...props.titular, ...titularData });
    setShowInfo('');
  };

  const titular_inputs = (titular) => {
    const {
      is_edit,
      show_in_step_two,
      is_profile,
      getTitularDocument,
      isAlly,
      isAllyMaster,
      isAllyMatrix,
      isAdmin,
      isLeadOperator,
      isCoordinator,
      token,
      updating,
      user_type,
      isSuper,
      no_offers_coupons,
      modeAdmin,
      readOnlyPlans,
      handleChangePassword,
      customers,
      isAdminOrAlly,
      is_ally_client,
      isoCode,
      interval
    } = props;

    const sexs = [
      {
        value: 'M',
        name: 'Masculino'
      },
      {
        value: 'F',
        name: 'Femenino'
      }
    ];
    const nacionalities = [
      {
        value: 'Cédula',
        name: 'Cédula'
      },
      {
        value: 'Pasaporte',
        name: 'Pasaporte'
      }
    ];

    return (
      <div className="flex flex-wrap w-full">
        {is_edit !== true &&
          show_in_step_two !== false &&
          is_profile !== true && (
            <span className="text-2xl text-gray-800 font-poppins font-bold">
              Ingresa los datos del titular
            </span>
          )}
        <div className="flex flex-wrap mt-4 headline-form">
          <TextInput
            name="first_name"
            label="Nombres"
            placeholder="Nombres"
            helperText={titular.formErrors?.first_name}
            errors={titular.formErrors?.first_name}
            value={titular.first_name}
            addClass={twoColumns ? 'wInputTwo' : 'wInputThree'}
            onChange={handleInput}
            required={true}
            FormHelperTextProps={{
              classes: {
                root: is_profile === true ? '' : 'cssHelperTextError'
              }
            }}
            disabled={titular.NotEditCustomer}
          />

          <TextInput
            name="last_name"
            label="Apellidos"
            placeholder="Apellidos"
            helperText={titular.formErrors?.last_name}
            errors={titular.formErrors?.last_name}
            value={titular.last_name}
            addClass={twoColumns ? 'wInputTwo' : 'wInputThree'}
            onChange={handleInput}
            required={true}
            FormHelperTextProps={{
              classes: {
                root: is_profile === true ? '' : 'cssHelperTextError'
              }
            }}
            disabled={titular.NotEditCustomer}
          />

          <SelectSimpleOutlined
            name="sex"
            label="Sexo"
            value={titular.sex}
            placeholder="Selecciona el sexo"
            listItems={sexs}
            addClass={`${twoColumns ? 'wInputTwo' : 'wInputThree'} ${
              titular.sex === 'default' && 'default'
            }`}
            helperText={titular.formErrors?.sex}
            errors={titular.formErrors?.sex}
            onChange={handleInputSex}
            required={true}
            FormHelperTextProps={{
              classes: {
                root: is_profile === true ? '' : 'cssHelperTextError'
              }
            }}
            disabled={titular.NotEditCustomer}
          />

          {/* Identifier... */}
          <SelectSimpleOutlined
            name="nationality"
            label="Tipo de Documento"
            value={titular.nationality}
            placeholder="Selecciona el tipo"
            listItems={nacionalities}
            addClass={`${twoColumns ? 'wInputTwo' : 'wInputThree'} ${
              titular.nationality === 'default' && 'default'
            }`}
            helperText={titular.formErrors?.nationality}
            errors={titular.formErrors?.nationality}
            onChange={handleInput}
            required={true}
            FormHelperTextProps={{
              classes: {
                root: is_profile === true ? '' : 'cssHelperTextError'
              }
            }}
            disabled={titular.NotEditCustomer}
          />

          <TextInput
            name="dni"
            label="Número de Documento"
            placeholder="Número de Documento"
            helperText={titular.formErrors?.dni}
            errors={titular.formErrors?.dni}
            value={titular.dni}
            addClass={twoColumns ? 'wInputTwo' : 'wInputThree'}
            onChange={handleInput}
            required={true}
            FormHelperTextProps={{
              classes: {
                root: is_profile === true ? '' : 'cssHelperTextError'
              }
            }}
            disabled={
              (customers && customers.length > 0
                ? true
                : updating === true && !isAdmin
                ? true
                : false) || titular.NotEditCustomer
            }
          />

          <FileWithIconInput
            name="document_dni"
            label="Documento de identidad"
            required={true}
            value={titular.document_dni}
            ifExist={titular.document_dni && getTitularDocument ? true : false}
            showView={titular.document_dni === 'Archivo cargado' ? true : false}
            placeholder="Seleccione el documento de identidad"
            addClass={twoColumns ? 'wInputTwo' : 'wInputThree'}
            changeClass={false}
            errors={titular.formErrors?.doc_dni}
            helperText={titular.formErrors?.doc_dni}
            onChange={(e) => handleInputFile(e)}
            onClick={() => getTitularDocument(titular._id)}
            disabled={titular.NotEditCustomer}
          />

          <DateInput
            name="birth_date"
            label="Fecha de Nacimiento"
            placeholder="Fecha de Nacimiento"
            required={!isAlly && !isAllyMaster && !isAllyMatrix}
            maxDate={
              new Date(new Date().setFullYear(new Date().getFullYear() - 18))
            }
            helperText={titular.formErrors?.birth_date}
            errors={titular.formErrors?.birth_date}
            value={titular.birth_date}
            addClass={twoColumns ? 'wInputTwo' : 'wInputThree'}
            onChange={(date) => handleBirthDate(date)}
            disabled={titular.NotEditCustomer}
          />

          <Tooltip
            open={
              router.pathname === '/profile' &&
              isChangeEmail &&
              !titular.email_updated &&
              !titular.email_confirm &&
              titular.mode === 'email'
            }
            title="Nota: Verifica que el correo sea el correcto, ya que solo podrás cambiarlo una vez."
            placement="top"
            arrow
          >
            <TextInput
              name="email"
              label="Correo electrónico"
              placeholder="Correo electrónico"
              type="email"
              helperText={titular.formErrors?.email}
              errors={titular.formErrors?.email}
              value={titular.email}
              addClass={twoColumns ? 'wInputTwo' : 'wInputThree'}
              onChange={handleInput}
              required={true}
              FormHelperTextProps={{
                classes: { root: is_profile ? '' : 'cssHelperTextError' }
              }}
              disabled={
                ((isAdmin || isSuper || isLeadOperator || isCoordinator) &&
                updating === false
                  ? false
                  : (token && user_type === 1) ||
                    (token && (isAlly || isAllyMaster || isAllyMatrix)) ||
                    updating === true
                  ? true
                  : false) || titular.NotEditCustomer
              }
            />
          </Tooltip>

          <ReactTelInput
            required={true}
            onChange={(telNumber) => handlePhone('phone_one', telNumber)}
            value={titular.phone_one || '+58'}
            defaultCountry="ve"
            flagsImagePath={Flags}
            // className={(!titular.phone_one) ? 'telInput' : (titular.formErrors?.phone_one) ? 'telInputError' : "telInput"}
            // ${twoColumns ? "wInputTwo" : "wInputThree"}
            className={
              titular.phone_one && !titular.formErrors?.phone_one
                ? `${twoColumns ? 'telInputTwo' : 'telInput'}`
                : (!titular.phone_one && titular.formErrors?.phone_one) ||
                  titular.formErrors?.phone_one
                ? 'telInputError'
                : `${twoColumns ? 'telInputTwo' : 'telInput'}`
            }
            disabled={titular.NotEditCustomer}
          />

          <TextField
            select
            name="country"
            label="País de residencia"
            helperText={titular.formErrors?.country}
            variant="outlined"
            className={`${twoColumns ? 'wInputTwo' : 'wInputThree'} ${
              titular.country === 'default' && 'default'
            }`}
            FormHelperTextProps={{
              classes: {
                root: is_profile === true ? '' : 'cssHelperTextError'
              }
            }}
            InputLabelProps={{
              classes: {
                root:
                  titular.country && !titular.formErrors?.country
                    ? 'cssLabel'
                    : (!titular.country && titular.formErrors?.country) ||
                      titular.formErrors?.country
                    ? 'cssLabelError'
                    : 'cssLabelSuccess'
              },
              shrink: true
            }}
            InputProps={{
              classes: {
                notchedOutline:
                  !titular.country && !titular.formErrors?.country
                    ? 'cssInput'
                    : (!titular.country && titular.formErrors?.country) ||
                      titular.formErrors?.country
                    ? 'cssInputError'
                    : 'cssInputSuccess'
              }
            }}
            SelectProps={{
              IconComponent: KeyboardArrowDownIcon
            }}
            margin="normal"
            error={true}
            value={titular.country || ''}
            required={!isAlly && !isAllyMaster && !isAllyMatrix}
            onChange={handleInputCountry}
            disabled={titular.NotEditCustomer}
          >
            <MenuItem value="default" hidden disabled>
              Selecciona el país
            </MenuItem>
            {titular.include_titular === true && (
              <MenuItem value="Venezuela">Venezuela</MenuItem>
            )}
            {(titular.include_titular === false ||
              !titular.include_titular ||
              is_profile === true ||
              isAdmin) &&
              CountryList.map((country, i) => (
                <MenuItem key={i} value={country.name}>
                  {country.name}
                </MenuItem>
              ))}
          </TextField>

          {(titular.include_titular === false || !titular.include_titular) && (
            <TextInput
              name="city"
              label="Ciudad"
              placeholder="Ciudad"
              helperText={titular.formErrors?.city}
              errors={titular.formErrors?.city}
              value={titular.city}
              addClass={twoColumns ? 'wInputTwo' : 'wInputThree'}
              onChange={handleInput}
              required={true}
              FormHelperTextProps={{
                classes: {
                  root: is_profile === true ? '' : 'cssHelperTextError'
                }
              }}
              disabled={titular.NotEditCustomer}
            />
          )}

          {titular.include_titular === true && (
            <TextField
              select
              name="city"
              label="Ciudad"
              placeholder="Ciudad"
              helperText={titular.formErrors?.city}
              variant="outlined"
              error={true}
              className={`wInputThree ${
                titular.city === 'default' && 'default'
              }`}
              FormHelperTextProps={{
                classes: {
                  root: is_profile === true ? '' : 'cssHelperTextError'
                }
              }}
              InputLabelProps={{
                classes: {
                  root:
                    titular.city && !titular.formErrors?.city
                      ? 'cssLabel'
                      : (!titular.city && titular.formErrors?.city) ||
                        titular.formErrors?.city
                      ? 'cssLabelError'
                      : 'cssLabelSuccess'
                },
                shrink: true
              }}
              InputProps={{
                classes: {
                  notchedOutline:
                    !titular.city && !titular.formErrors?.city
                      ? 'cssInput'
                      : (!titular.city && titular.formErrors?.city) ||
                        titular.formErrors?.city
                      ? 'cssInputError'
                      : 'cssInputSuccess'
                }
              }}
              SelectProps={{
                IconComponent: KeyboardArrowDownIcon
              }}
              margin="normal"
              value={titular.city || ''}
              required={!isAlly && !isAllyMaster && !isAllyMatrix}
              onChange={(event) => handleInput(event)}
              disabled={titular.NotEditCustomer}
            >
              <MenuItem value="default" hidden disabled>
                Selecciona la ciudad
              </MenuItem>
              {CityList.map((city, index) => (
                <MenuItem key={index} value={city.name}>
                  {city.name}
                </MenuItem>
              ))}
            </TextField>
          )}

          <TextInput
            name="address"
            label="Dirección"
            placeholder="Dirección"
            helperText={titular.formErrors?.address}
            errors={titular.formErrors?.address}
            value={titular.address}
            addClass={twoColumns ? 'wInputTwo' : 'wInputThree'}
            onChange={handleInput}
            required={!isAlly && !isAllyMaster && !isAllyMatrix}
            FormHelperTextProps={{
              classes: {
                root: is_profile === true ? '' : 'cssHelperTextError'
              }
            }}
            disabled={titular.NotEditCustomer}
          />
          {((is_profile !== true &&
            titular.include_titular === true &&
            !isAdmin) ||
            (is_profile !== true &&
              titular.include_titular === true &&
              isAdmin &&
              titular.self_paid)) && (
            <>
              <TextInput
                type="number"
                name="heigth"
                label="Estatura"
                placeholder="Escriba su estatura en metros"
                helperText={titular.formErrors?.heigth}
                errors={titular.formErrors?.heigth}
                error={titular.formErrors?.heigth ? true : false}
                value={titular.heigth}
                addClass={twoColumns ? 'wInputTwo' : 'wInputThree'}
                onChange={handleInput}
                FormHelperTextProps={{
                  classes: {
                    root: is_profile === true ? '' : 'cssHelperTextError'
                  }
                }}
                disabled={!!readOnlyPlans || titular.NotEditCustomer}
                prefix="m"
              />
              <TextInput
                type="number"
                name="weight"
                label="Peso"
                placeholder="Peso"
                helperText={titular.formErrors?.weight}
                errors={titular.formErrors?.weight}
                error={titular.formErrors?.weight ? true : false}
                value={titular.weight}
                addClass={twoColumns ? 'wInputTwo' : 'wInputThree'}
                onChange={handleInput}
                FormHelperTextProps={{
                  classes: {
                    root: is_profile === true ? '' : 'cssHelperTextError'
                  }
                }}
                disabled={!!readOnlyPlans || titular.NotEditCustomer}
                prefix="kg"
              />
            </>
          )}

          {is_profile === true && (
            <Tooltip
              title={
                titular.status
                  ? 'Si no posee contraseña, puede crearla aquí'
                  : 'No puede cambiar la contraseña porque su usuario aun no ha sido activado'
              }
            >
              <TextField
                name="password"
                label="Contraseña"
                placeholder="********"
                type="text"
                helperText={titular.formErrors?.password}
                variant="outlined"
                className="wInputThree"
                FormHelperTextProps={{
                  classes: {
                    root: is_profile === true ? '' : 'cssHelperTextError'
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: 'cssLabel'
                  },
                  shrink: true
                }}
                InputProps={{
                  classes: {
                    notchedOutline: 'cssInput'
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <button
                        className={`${
                          titular.status ? 'text-pink-500' : 'text-gray-500'
                        } text-small tracking-extra cursor-pointer text-center font-bold font-poppins uppercase`}
                        aria-label="change password"
                        onClick={() => handleChangePassword(true)}
                        disabled={!titular.status}
                      >
                        CAMBIAR
                      </button>
                    </InputAdornment>
                  )
                }}
                margin="normal"
                value=""
                disabled={true}
                onChange={handleInput}
              />
            </Tooltip>
          )}
          {(isAlly || isAllyMaster || isAllyMatrix) &&
            !no_offers_coupons === true &&
            modeAdmin === true &&
            is_profile !== true && (
              <div className="flex w-full">
                <FormControlLabel
                  className="nm w-60"
                  label={
                    <span
                      className={`text-gray-800 text-base ${
                        titular.promo_code === true
                          ? 'font-bold'
                          : 'font-normal'
                      }`}
                    >
                      Otorgar cupón de 5% de descuento
                    </span>
                  }
                  control={
                    <Checkbox
                      name="promo_code"
                      required={true}
                      className={
                        titular.promo_code === true
                          ? 'checkBoxActive'
                          : 'checkBox'
                      }
                      onClick={(e) =>
                        handleCheckbox(
                          e,
                          titular.promo_code === true ? false : true
                        )
                      }
                      checked={titular.promo_code || false}
                      disabled={titular.NotEditCustomer}
                    />
                  }
                />
              </div>
            )}

          {(isAlly || isAllyMaster || isAllyMatrix) &&
            modeAdmin === true &&
            is_profile !== true && (
              <div className="flex w-full">
                <FormControlLabel
                  className="nm w-60"
                  label={
                    <span
                      className={`text-gray-800 text-base ${
                        titular.assisted_selling === true
                          ? 'font-bold'
                          : 'font-normal'
                      }`}
                    >
                      Quiero que un Big Broder impulse la afiliación
                      <Tooltip title="Quiero que un Big Broder impulse la afiliación">
                        <span className="font-bold text-purple-500"> (?)</span>
                      </Tooltip>
                    </span>
                  }
                  control={
                    <Checkbox
                      name="assisted_selling"
                      required={true}
                      className={
                        titular.assisted_selling === true
                          ? 'checkBoxActive'
                          : 'checkBox'
                      }
                      onClick={(e) =>
                        handleCheckboxAssistedSelling(
                          e,
                          titular.assisted_selling === true ? false : true
                        )
                      }
                      checked={titular.assisted_selling || false}
                      disabled={titular.NotEditCustomer}
                    />
                  }
                />
              </div>
            )}

          {titular.assisted_selling && (
            <div className="bg-purple-100 p-3 rounded mb-5">
              <p>
                Con esta opción uno de nuestros asesores comerciales contactará
                al titular para explicar en detalle el producto, responder sus
                dudas e impulsar la afiliación. Nosotros nos encargamos de
                concretar la afiliación y ¡tú recibes el pago por referido!
              </p>
            </div>
          )}
          {((is_profile !== true &&
            titular.include_titular === true &&
            !isAdmin) ||
            (is_profile !== true &&
              titular.include_titular === true &&
              isAdmin &&
              titular.self_paid)) && (
            <div className="w-full mt-4 mx-2 hidden md:block">
              <table
                className="border-gray-300 border bg-white w-full"
                cellPadding="10"
                cellSpacing="0"
              >
                <tbody>
                  <tr className="h-16">
                    <td>
                      <span className="text-gray-800 text-base font-bold leading-relaxed pl-2">
                        Planes de Salud
                      </span>
                    </td>
                    {isArray(titular.products) &&
                      titular.birth_date &&
                      titular.products.map((product, i) => (
                        <td key={i}>
                          <FormControlLabel
                            name="product"
                            value={String(product._id)}
                            control={
                              <Radio
                                value={String(product._id)}
                                className={
                                  titular.product_id === product._id
                                    ? 'checkBoxActive'
                                    : 'checkBox'
                                }
                                checked={
                                  titular.product_id === product._id
                                    ? true
                                    : false
                                }
                              />
                            }
                            onChange={() => handleSelectPlan(product)}
                            label={
                              <span
                                className={`text-gray-800 text-normal ${
                                  titular.product_id === product._id
                                    ? 'font-bold'
                                    : 'font-normal'
                                }`}
                              >
                                {product.name}
                              </span>
                            }
                            labelPlacement="end"
                            disabled={
                              !!readOnlyPlans || titular.NotEditCustomer
                            }
                          />
                        </td>
                      ))}
                    {loading && (
                      <td align="start">
                        <CircularProgress
                          className="text-gray-300 m-1"
                          size={24}
                          thickness={3}
                        />
                      </td>
                    )}
                    {!titular.birth_date && (
                      <td className="text-gray-600 text-normal">
                        Debe ingresar su fecha de nacimiento
                      </td>
                    )}
                    <td className="w-48">
                      <span className="text-normal leading-relaxed">
                        {titular.total && titular.product_type ? (
                          <span className="text-gray-800 text-base">
                            {isoCode && isoCode.toUpperCase()}.
                            {
                              titular?.product_type?.intervals[interval][
                                isoCode
                              ]
                            }
                          </span>
                        ) : (
                          <span className="text-gray-600">
                            {isoCode && isoCode.toUpperCase()}.0
                          </span>
                        )}
                      </span>
                      <span className="ml-1">
                        <SelectInterval />
                      </span>
                    </td>
                    <td align="end">
                      <span className="pr-2">
                        <SelectCurrency hideFlag={true} />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          {/* RESPONSIVE PLANS */}
          {((is_profile !== true &&
            titular.include_titular === true &&
            !isAdmin) ||
            (is_profile !== true &&
              titular.include_titular === true &&
              isAdmin &&
              titular.self_paid)) && (
            <div className="w-full border border-gray-300 mt-6 mb-2 bg-white md:hidden">
              <div className="flex items-center bg-white border-b border-gray-300 min-h-50p px-5">
                <span className="text-gray-800 text-normal font-bold">
                  Selecciona el plan
                </span>
              </div>
              <div className="text-gray-800 text-normal flex justify-between p-5 border-b border-gray-300">
                <span className="font-bold">Plan</span>
                <div className="flex flex-col items-end">
                  {titular.products &&
                    titular.products.length > 0 &&
                    titular.products.map((product, i) => (
                      <span key={i} className="m-b5">
                        <FormControlLabel
                          key={i}
                          name="product"
                          value={String(product._id)}
                          control={
                            <Radio
                              value={String(product._id)}
                              className={
                                titular.product_id === product._id
                                  ? 'checkBoxActive'
                                  : 'checkBox'
                              }
                              checked={
                                titular.product_id === product._id
                                  ? true
                                  : false
                              }
                            />
                          }
                          onChange={() => handleSelectPlan(product)}
                          label={
                            <span
                              className={`text-gray-800 text-normal ${
                                titular.product_id === product._id
                                  ? 'font-bold'
                                  : 'font-normal'
                              }`}
                            >
                              {product.name}
                            </span>
                          }
                          labelPlacement="start"
                          disabled={!!readOnlyPlans || titular.NotEditCustomer}
                        />
                      </span>
                    ))}
                </div>
              </div>
              <div className="text-gray-800 text-normal flex items-center justify-between px-5 min-h-50p">
                <span className="font-bold">Valor</span>
                <span>
                  {titular.total && titular?.product_type ? (
                    <span className="text-gray-800 text-sm">
                      {isoCode && isoCode.toUpperCase()}.
                      {titular?.product_type?.intervals[interval][isoCode]}
                    </span>
                  ) : (
                    <span className="text-gray-600">
                      {isoCode && isoCode.toUpperCase()}.0
                    </span>
                  )}
                  <span className="ml-2">
                    <SelectInterval />
                  </span>
                  <span className="ml-2">
                    <SelectCurrency hideFlag={true} />
                  </span>
                </span>
              </div>
            </div>
          )}
          <SnackBar
            openAlert={alert.openAlert}
            messageAlert={alert.messageAlert}
            handleClose={handleClose}
          />
        </div>
      </div>
    );
  };

  return <div>{titular_inputs(props.titular)}</div>;
};

export default HeadlineForm;

export const _calculateAge = (birthday) => {
  const today = new Date();
  const birthDate = new Date(birthday);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthToday = today.getMonth() + 1;
  const monthBirthDate = birthDate.getMonth() + 1;
  const m = monthToday - monthBirthDate;
  let actorial = 0;

  if (monthToday > monthBirthDate && m > 6) {
    actorial = 1;
  } else if (
    monthToday < monthBirthDate &&
    12 - monthBirthDate + monthToday === 6 &&
    today.getDate() >= birthDate.getDate()
  ) {
    actorial = 1;
  } else if (
    monthToday < monthBirthDate &&
    12 - monthBirthDate + monthToday > 6
  ) {
    actorial = 1;
  } else if (
    monthToday === monthBirthDate &&
    birthDate.getDate() > today.getDate()
  ) {
    actorial = 1;
  }

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }

  return age + actorial;
};

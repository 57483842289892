import React, { Component, useState, useEffect } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import {
  TextField,
  MenuItem,
  Radio,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core";
import ReactTelInput from "react-telephone-input";
import Validations from "../utils/Validations";

import { SnackBar } from "../widgets";
import { TextInput, DateInput, SelectCurrency } from "components/commons";
import { isArray } from "util";
import CityList from "components/utils/CityList";
import SelectInterval from "../commons/SelectInterval";
import { _calculateAge } from "../utils/CalculateAge";

const serverUrlImages = process.env.REACT_APP_SERVER_URL_IMAGES;
const Flags = `${serverUrlImages}/images/flags.png`;

const BeneficiaryForm = (props) => {
  const {
    beneficiary,
    index,
    is_profile,
    isAlly,
    beneficiaries,
    isRenew,
    updatePlan,
    isAdminOrAlly,
    currentPlan,
    editMember,
    is_ally_client,
    isoCode,
    intervals,
    interval,
  } = props;

  const [alert, setAlert] = useState({
    openAlert: false,
    messageAlert: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getInitialData = async () => {
      const {
        stepIndex,
        beneficiaries,
        index,
        saveState,
        beneficiary,
        currentPlan,
        setCurrentPlan,
        is_profile,
        isRenew,
      } = props;
      const beneficiariesData = [...beneficiaries];
      //Quotation Flow - Get Age
      let age = beneficiary.age;
      if (beneficiary.birth_date && !beneficiary.age) {
        age = await _calculateAge(beneficiary.birth_date);
        beneficiariesData[index].age = age;
      }

      if (
        !is_profile &&
        (!isNaN(parseInt(beneficiary.age)) || !isNaN(parseInt(age))) &&
        (!beneficiary.products ||
          beneficiary.products?.length <= 0 ||
          !beneficiary.products[0]?.intervals)
      ) {
        const result = await props.getProductsByFrequency({
          age: beneficiary.age || age,
        });
        if (result.products)
          beneficiariesData[index].products = result.products;
      }

      if (!is_profile && beneficiary.product_id) {
        const productType =
          beneficiary.product_type &&
          typeof beneficiary.product_type === "string"
            ? JSON.parse(beneficiary.product_type)
            : beneficiary.product_type;
        const findProduct = beneficiary?.products?.find(
          (product) => product._id === beneficiary.product_id
        );
        beneficiariesData[index].product_type = productType?.intervals
          ? productType
          : findProduct;

        if (!currentPlan[index] && isRenew)
          setCurrentPlan(index, beneficiary.product_id);
      }
      saveState("beneficiaries", beneficiariesData);
    };
    getInitialData();
  }, []);

  const handleClose = () => setAlert({ ...alert, openAlert: false });

  const handleInput = async (event, index) => {
    let { name, value } = event.target;
    const beneficiariesData = [...props.beneficiaries];
    beneficiariesData[index][name] = value;
    props.saveState("beneficiaries", [...beneficiariesData]);
    Validations.validateFieldBeneficiaries(
      beneficiariesData,
      props.saveState,
      name,
      value,
      index
    );
  };

  const handleInputSex = async (event, index) => {
    let { name, value } = event.target;
    const beneficiariesData = [...props.beneficiaries];
    beneficiariesData[index][name] = value;
    props.saveState("beneficiaries", [...beneficiariesData]);
    Validations.validateFieldBeneficiaries(
      beneficiaries,
      props.saveState,
      name,
      value,
      index
    );
  };

  const handlePhone = (name, value, index) => {
    const beneficiariesData = [...props.beneficiaries];
    beneficiariesData[index][name] = value;
    props.saveState("beneficiaries", [...beneficiariesData]);
    Validations.validateFieldBeneficiaries(
      beneficiariesData,
      props.saveState,
      name,
      value,
      index
    );
  };

  const handleSelectPlan = async (product, index) => {
    const beneficiariesData = [...props.beneficiaries];
    beneficiariesData[index].product_type = product;
    beneficiariesData[index].product_id = product._id;
    beneficiariesData[index].product_price = product.product_price;
    beneficiariesData[index].total = product.product_price;
    props.saveState("beneficiaries", [...beneficiariesData]);
  };

  const handleBirthDate = async (date, index) => {
    setLoading(true);
    let beneficiariesData = [...props.beneficiaries];
    beneficiariesData[index].birth_date = date;
    // await props.saveState("beneficiaries", beneficiaries);
    if (date && date instanceof Date && !isNaN(date.valueOf())) {
      const age = _calculateAge(date);
      beneficiariesData[index].age = age;
      const result = await props.getProductsByFrequency({ age });
      if (result.products) beneficiariesData[index].products = result.products;
      if (result.products && beneficiaries[index].product_id) {
        const productType = result.products.find(
          (p) => beneficiaries[index].product_id === p._id
        );
        handleSelectPlan(productType, index);
      }
      if (result.success === false) {
        setAlert({
          openAlert: result?.openAlert,
          messageAlert: result?.messageAlert,
        });
      }
    }
    props.saveState("beneficiaries", beneficiariesData);
    setLoading(false);
    Validations.validateFieldBeneficiaries(
      [...beneficiariesData],
      props.saveState,
      "birth_date",
      date,
      index
    );
  };

  return (
    <div className="form-group w-full" key={index}>
      <div className="flex flex-wrap beneficiary-form">
      {!(beneficiary.kinship === 'TITULAR' && is_profile) && (
        <TextField
          select
          label="Parentesco"
          name="kinship"
          value={beneficiary.kinship}
          onChange={(event) => handleInput(event, index)}
          variant="outlined"
          error={true}
          required={true}
          className={`wInputThree ${
            beneficiary.kinship === "default" && "default"
          }`}
          FormHelperTextProps={{
            classes: { root: is_profile === true ? "" : "cssHelperTextError" },
          }}
          InputLabelProps={{
            classes: {
              // root: (!beneficiary.kinship || beneficiary.kinship === 'default') ? 'cssLabel' : (beneficiary.formErrors?.kinship) ? 'cssLabelError' : 'cssLabelSuccess',
              root:
                beneficiary.kinship && !beneficiary.formErrors?.kinship
                  ? "cssLabel"
                  : (!beneficiary.kinship && beneficiary.formErrors?.kinship) ||
                    beneficiary.formErrors?.kinship
                  ? "cssLabelError"
                  : "cssLabelSuccess",
            },
            shrink: true,
          }}
          InputProps={{
            classes: {
              // notchedOutline: (!beneficiary.kinship || beneficiary.kinship === 'default') ? 'cssInput' : (beneficiary.formErrors?.kinship) ? 'cssInputError': 'cssInputSuccess'
              notchedOutline:
                !beneficiary.kinship && !beneficiary.formErrors?.kinship
                  ? "cssInput"
                  : (!beneficiary.kinship && beneficiary.formErrors?.kinship) ||
                    beneficiary.formErrors?.kinship
                  ? "cssInputError"
                  : "cssInputSuccess",
            },
          }}
          SelectProps={{
            IconComponent: KeyboardArrowDownIcon,
          }}
          helperText={beneficiary.formErrors?.kinship}
          margin="normal"
          // disabled={is_profile === true ? true : false}
          disabled={(is_profile && !editMember) || beneficiary.NotEditCustomer}
        >
          <MenuItem value="default" hidden disabled>
            Selecciona el parentesco
          </MenuItem>
          <MenuItem value="Hijo">Hijo/a</MenuItem>
          {beneficiaries.length > 1 &&
            !beneficiaries.find((b) => b.kinship === "Conyugue") && (
              <MenuItem value="Conyugue">Cónyuge</MenuItem>
            )}
          {(beneficiaries.length === 1 ||
            beneficiary.kinship === "Conyugue") && (
            <MenuItem value="Conyugue">Cónyuge</MenuItem>
          )}
          {(!beneficiaries.find((b) => b.kinship === "Madre") ||
            beneficiary.kinship === "Madre") && (
            <MenuItem value="Madre">Madre</MenuItem>
          )}
          {(!beneficiaries.find((b) => b.kinship === "Padre") ||
            beneficiary.kinship === "Padre") && (
            <MenuItem value="Padre">Padre</MenuItem>
          )}

          <MenuItem value="Hermano">Hermano/a</MenuItem>
          <MenuItem value="Abuelo">Abuelo/a</MenuItem>
          {(beneficiary.kinship !== "" || beneficiary.kinship === "Otro") &&
            beneficiary.kinship !== "Hijo" &&
            beneficiary.kinship !== "Conyugue" &&
            beneficiary.kinship !== "Madre" &&
            beneficiary.kinship !== "Padre" &&
            beneficiary.kinship !== "Hermano" &&
            beneficiary.kinship !== "default" && (
              <MenuItem
                value={
                  beneficiary.kinship === "Otro"
                    ? beneficiary.other_kinship
                    : beneficiary.kinship
                }
              >
                {beneficiary.kinship === "Otro"
                  ? beneficiary.other_kinship
                  : beneficiary.kinship}
              </MenuItem>
            )}
          <MenuItem value="Otro">Otro</MenuItem>
        </TextField>
      )}
        {beneficiary.kinship === "Otro" && (
          <TextInput
            name="other_kinship"
            label="Especifique parentesco"
            placeholder="Parentesco"
            helperText={beneficiary.formErrors?.other_kinship}
            errors={beneficiary.formErrors?.other_kinship}
            value={beneficiary.other_kinship}
            addClass="wInputThree"
            onChange={(event) => handleInput(event, index)}
            required={isAlly === true ? false : true}
            FormHelperTextProps={{
              classes: {
                root: is_profile === true ? "" : "cssHelperTextError",
              },
            }}
            disabled={beneficiary.NotEditCustomer}
          />
        )}

        <TextInput
          name="first_name"
          label="Nombres"
          placeholder="Nombres"
          helperText={beneficiary.formErrors?.first_name}
          errors={beneficiary.formErrors?.first_name}
          value={beneficiary.first_name}
          addClass="wInputThree"
          onChange={(event) => handleInput(event, index)}
          required={isAlly === true ? false : true}
          FormHelperTextProps={{
            classes: { root: is_profile === true ? "" : "cssHelperTextError" },
          }}
          disabled={beneficiary.NotEditCustomer}
        />

        <TextInput
          name="last_name"
          label="Apellidos"
          placeholder="Apellidos"
          helperText={beneficiary.formErrors?.last_name}
          errors={beneficiary.formErrors?.last_name}
          value={beneficiary.last_name}
          addClass="wInputThree"
          onChange={(event) => handleInput(event, index)}
          required={isAlly === true ? false : true}
          FormHelperTextProps={{
            classes: { root: is_profile === true ? "" : "cssHelperTextError" },
          }}
          disabled={beneficiary.NotEditCustomer}
        />

        <DateInput
          clearable={true}
          name="birth_date"
          label="Fecha de Nacimiento"
          placeholder="Fecha de Nacimiento"
          format="dd MMM yyyy"
          maxDate={new Date()}
          helperText={beneficiary.formErrors?.birth_date}
          errors={beneficiary.formErrors?.birth_date}
          value={beneficiary.birth_date}
          addClass="wInputThree"
          onChange={(date) => handleBirthDate(date, index)}
          disabled={
            is_profile === true || isRenew || beneficiary.NotEditCustomer
          }
        />

        {/* Identifier... */}
        <TextField
          select
          label="Tipo de Documento"
          name="nationality"
          value={beneficiary.nationality}
          onChange={(event) => handleInput(event, index)}
          variant="outlined"
          error={true}
          required={true}
          className={`field-responsive wInputThree m-t25 ${
            beneficiary.nationality === "default" && "default"
          }`}
          FormHelperTextProps={{
            classes: { root: is_profile === true ? "" : "cssHelperTextError" },
          }}
          InputLabelProps={{
            classes: {
              // root: (!beneficiary.nationality || beneficiary.nationality === 'default') ? 'cssLabel' : (beneficiary.formErrors?.nationality) ? 'cssLabelError' : 'cssLabelSuccess',
              root:
                beneficiary.nationality && !beneficiary.formErrors?.nationality
                  ? "cssLabel"
                  : (!beneficiary.nationality &&
                      beneficiary.formErrors?.nationality) ||
                    beneficiary.formErrors?.nationality
                  ? "cssLabelError"
                  : "cssLabelSuccess",
            },
            shrink: true,
          }}
          InputProps={{
            classes: {
              // notchedOutline: (!beneficiary.nationality || beneficiary.nationality === 'default') ? 'cssInput' : (beneficiary.formErrors?.nationality) ? 'cssInputError': 'cssInputSuccess'
              notchedOutline:
                !beneficiary.nationality && !beneficiary.formErrors?.nationality
                  ? "cssInput"
                  : (!beneficiary.nationality &&
                      beneficiary.formErrors?.nationality) ||
                    beneficiary.formErrors?.nationality
                  ? "cssInputError"
                  : "cssInputSuccess",
            },
          }}
          SelectProps={{
            IconComponent: KeyboardArrowDownIcon,
          }}
          helperText={beneficiary.formErrors?.nationality}
          margin="normal"
          disabled={beneficiary.NotEditCustomer}
        >
          <MenuItem value="default" hidden disabled>
            Selecciona el tipo de documento
          </MenuItem>
          {beneficiary.age < 10 && (
            <MenuItem value="Menor de Edad">Menor de Edad</MenuItem>
          )}
          <MenuItem value="Cédula">Cédula</MenuItem>
          <MenuItem value="Pasaporte">Pasaporte</MenuItem>
        </TextField>

        <TextInput
          name="dni"
          label={
            beneficiary.nationality === "Menor de Edad"
              ? "Número de Documento responsable"
              : "Número de Documento"
          }
          placeholder="Número de Documento"
          helperText={beneficiary.formErrors?.dni}
          errors={beneficiary.formErrors?.dni}
          value={beneficiary.dni}
          addClass="wInputThree"
          onChange={(event) => handleInput(event, index)}
          required={isAlly === true ? false : true}
          disabled={
            is_profile === true || isRenew || beneficiary.NotEditCustomer
          }
          FormHelperTextProps={{
            classes: {
              root: is_profile === true || isRenew ? "" : "cssHelperTextError",
            },
          }}
        />

        <TextField
          select
          label="Sexo"
          name="sex"
          value={beneficiary.sex}
          onChange={(event) => handleInputSex(event, index)}
          variant="outlined"
          error={true}
          required={true}
          className={`field-responsive wInputThree m-t25 ${
            beneficiary.sex === "default" && "default"
          }`}
          FormHelperTextProps={{
            classes: { root: is_profile === true ? "" : "cssHelperTextError" },
          }}
          InputLabelProps={{
            classes: {
              // root: (!beneficiary.sex || beneficiary.sex === 'default') ? 'cssLabel' : (beneficiary.formErrors?.sex) ? 'cssLabelError' : 'cssLabelSuccess',
              root:
                beneficiary.sex && !beneficiary.formErrors?.sex
                  ? "cssLabel"
                  : (!beneficiary.sex && beneficiary.formErrors?.sex) ||
                    beneficiary.formErrors?.sex
                  ? "cssLabelError"
                  : "cssLabelSuccess",
            },
            shrink: true,
          }}
          InputProps={{
            classes: {
              // notchedOutline: (!beneficiary.sex || beneficiary.sex === 'default') ? 'cssInput' : (beneficiary.formErrors?.sex) ? 'cssInputError': 'cssInputSuccess'
              notchedOutline:
                !beneficiary.sex && !beneficiary.formErrors?.sex
                  ? "cssInput"
                  : (!beneficiary.sex && beneficiary.formErrors?.sex) ||
                    beneficiary.formErrors?.sex
                  ? "cssInputError"
                  : "cssInputSuccess",
            },
          }}
          SelectProps={{
            IconComponent: KeyboardArrowDownIcon,
          }}
          helperText={beneficiary.formErrors?.sex}
          margin="normal"
          disabled={beneficiary.NotEditCustomer}
        >
          <MenuItem value="default" hidden disabled>
            Selecciona el sexo
          </MenuItem>
          <MenuItem value="F">Femenino</MenuItem>
          <MenuItem value="M">Masculino</MenuItem>
        </TextField>

        <TextInput
          name="email"
          label="Correo Electrónico"
          placeholder="Correo Electrónico"
          helperText={beneficiary.formErrors?.email}
          errors={beneficiary.formErrors?.email}
          value={beneficiary.email}
          addClass="wInputThree"
          onChange={(event) => handleInput(event, index)}
          required={isAlly === true ? false : true}
          FormHelperTextProps={{
            classes: { root: is_profile === true ? "" : "cssHelperTextError" },
          }}
          disabled={beneficiary.NotEditCustomer}
        />

        <ReactTelInput
          required={isAlly === true ? false : true}
          onChange={(telNumber) => handlePhone("phone_one", telNumber, index)}
          defaultCountry="ve"
          value={beneficiary.phone_one || "+58"}
          flagsImagePath={Flags}
          // className={(!beneficiary.phone_one) ? 'telInput' : (beneficiary.formErrors?.phone_one) ? 'telInputError' : "telInput"}
          className={
            beneficiary.phone_one && !beneficiary.formErrors?.phone_one
              ? "telInput"
              : (!beneficiary.phone_one && beneficiary.formErrors?.phone_one) ||
                beneficiary.formErrors?.phone_one
              ? "telInputError"
              : "telInput"
          }
          disabled={beneficiary.NotEditCustomer}
        />

        <TextField
          select
          name="country"
          label="País"
          placeholder="País"
          helperText={beneficiary.formErrors?.country}
          variant="outlined"
          error={true}
          className={`field-responsive wInputThree m-t25 ${
            beneficiary.country === "default" && "default"
          }`}
          FormHelperTextProps={{
            classes: { root: is_profile === true ? "" : "cssHelperTextError" },
          }}
          InputLabelProps={{
            classes: {
              // root: (!beneficiary.country || beneficiary.country === 'default') ? 'cssLabel' : (beneficiary.formErrors?.country) ? 'cssLabelError' : 'cssLabelSuccess',
              root:
                beneficiary.country && !beneficiary.formErrors?.country
                  ? "cssLabel"
                  : (!beneficiary.country && beneficiary.formErrors?.country) ||
                    beneficiary.formErrors?.country
                  ? "cssLabelError"
                  : "cssLabelSuccess",
            },
            shrink: true,
          }}
          InputProps={{
            classes: {
              // notchedOutline: (!beneficiary.country || beneficiary.country === 'default') ? 'cssInput' : (beneficiary.formErrors?.country) ? 'cssInputError': 'cssInputSuccess'
              notchedOutline:
                !beneficiary.country && !beneficiary.formErrors?.country
                  ? "cssInput"
                  : (!beneficiary.country && beneficiary.formErrors?.country) ||
                    beneficiary.formErrors?.country
                  ? "cssInputError"
                  : "cssInputSuccess",
            },
          }}
          SelectProps={{
            IconComponent: KeyboardArrowDownIcon,
          }}
          margin="normal"
          value={beneficiary.country}
          required={isAlly === true ? false : true}
          onChange={(event) => handleInput(event, index)}
          // disabled={is_profile === true ? true : false}
          disabled={(is_profile && !editMember) || beneficiary.NotEditCustomer}
        >
          <MenuItem value="default" hidden disabled>
            Selecciona el país
          </MenuItem>
          {["Venezuela"].map((country) => (
            <MenuItem key={country} value={country}>
              {country}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          name="city"
          label="Ciudad"
          placeholder="Ciudad"
          helperText={beneficiary.formErrors?.city}
          variant="outlined"
          error={true}
          className={`field-responsive wInputThree m-t25 ${
            beneficiary.city === "default" && "default"
          }`}
          FormHelperTextProps={{
            classes: { root: is_profile === true ? "" : "cssHelperTextError" },
          }}
          InputLabelProps={{
            classes: {
              // root: (!beneficiary.city || beneficiary.city === 'default') ? 'cssLabel' : (beneficiary.formErrors?.city) ? 'cssLabelError' : 'cssLabelSuccess',
              root:
                beneficiary.city && !beneficiary.formErrors?.city
                  ? "cssLabel"
                  : (!beneficiary.city && beneficiary.formErrors?.city) ||
                    beneficiary.formErrors?.city
                  ? "cssLabelError"
                  : "cssLabelSuccess",
            },
            shrink: true,
          }}
          InputProps={{
            classes: {
              // notchedOutline: (!beneficiary.city || beneficiary.city === 'default') ? 'cssInput' : (beneficiary.formErrors?.city) ? 'cssInputError': 'cssInputSuccess'
              notchedOutline:
                !beneficiary.city && !beneficiary.formErrors?.city
                  ? "cssInput"
                  : (!beneficiary.city && beneficiary.formErrors?.city) ||
                    beneficiary.formErrors?.city
                  ? "cssInputError"
                  : "cssInputSuccess",
            },
          }}
          SelectProps={{
            IconComponent: KeyboardArrowDownIcon,
          }}
          margin="normal"
          value={beneficiary.city}
          required={isAlly === true ? false : true}
          onChange={(event) => handleInput(event, index)}
          disabled={beneficiary.NotEditCustomer}
        >
          <MenuItem value="default" hidden disabled>
            Selecciona la ciudad
          </MenuItem>
          {CityList.map((city, index) => (
            <MenuItem key={index} value={city.name}>
              {city.name}
            </MenuItem>
          ))}
        </TextField>

        <TextInput
          name="address"
          label="Dirección"
          placeholder="Dirección"
          helperText={beneficiary.formErrors?.address}
          errors={beneficiary.formErrors?.address}
          value={beneficiary.address}
          addClass="wInputThree"
          onChange={(event) => handleInput(event, index)}
          required={isAlly === true ? false : true}
          FormHelperTextProps={{
            classes: { root: is_profile === true ? "" : "cssHelperTextError" },
          }}
          disabled={beneficiary.NotEditCustomer}
        />
        {is_profile !== true && (
          <>
            <TextInput
              type="number"
              name="heigth"
              label="Estatura"
              placeholder="Escriba su estatura en metros"
              helperText={beneficiary.formErrors?.heigth}
              errors={beneficiary.formErrors?.heigth}
              error={beneficiary.formErrors?.heigth ? true : false}
              value={beneficiary.heigth}
              addClass="wInputThree"
              onChange={(event) => handleInput(event, index)}
              FormHelperTextProps={{
                classes: {
                  root: is_profile === true ? "" : "cssHelperTextError",
                },
              }}
              required={isAlly === true ? false : true}
              prefix="m"
              disabled={beneficiary.NotEditCustomer}
            />

            <TextInput
              type="number"
              name="weight"
              label="Peso"
              placeholder="Escriba su peso en kg"
              helperText={beneficiary.formErrors?.weight}
              errors={beneficiary.formErrors?.weight}
              error={beneficiary.formErrors?.weight ? true : false}
              value={beneficiary.weight}
              addClass="wInputThree"
              onChange={(event) => handleInput(event, index)}
              FormHelperTextProps={{
                classes: {
                  root: is_profile === true ? "" : "cssHelperTextError",
                },
              }}
              required={isAlly === true ? false : true}
              prefix="kg"
              disabled={beneficiary.NotEditCustomer}
            />

            <div className="w-full mt-4 mx-2 hidden md:block">
              <table
                className="border-gray-300 border bg-white w-full"
                cellPadding="10"
                cellSpacing="0"
              >
                <tbody>
                  <tr className="h-16">
                    <td>
                      <span className="text-gray-800 text-base font-bold leading-relaxed pl-2">
                        Planes de Salud
                      </span>
                    </td>
                    {isArray(beneficiary.products) &&
                      beneficiary.birth_date &&
                      beneficiary.products.map((product, i) => (
                        <td key={i}>
                          <FormControlLabel
                            name="product"
                            value={String(product._id)}
                            control={
                              <Radio
                                value={String(product._id)}
                                className={
                                  beneficiary.product_id === product._id
                                    ? "checkBoxActive"
                                    : "checkBox"
                                }
                                checked={
                                  beneficiary.product_id === product._id
                                    ? true
                                    : false
                                }
                              />
                            }
                            onChange={() => handleSelectPlan(product, index)}
                            label={
                              <span
                                className={`text-gray-800 text-normal ${
                                  beneficiary.product_id === product._id
                                    ? "font-bold"
                                    : "font-normal"
                                }`}
                              >
                                {product.name}
                              </span>
                            }
                            labelPlacement="end"
                            disabled={beneficiary.NotEditCustomer}
                          />
                        </td>
                      ))}
                    {loading && (
                      <td align="start">
                        <CircularProgress
                          className="text-gray-300 m-1"
                          size={24}
                          thickness={3}
                        />
                      </td>
                    )}
                    {!beneficiary.birth_date && (
                      <td className="text-gray-600 text-normal">
                        Debe ingresar su fecha de nacimiento
                      </td>
                    )}
                    <td className="w-48">
                      <span className="text-normal leading-relaxed">
                        {beneficiary?.product_type &&
                        beneficiary?.product_type.intervals ? (
                          <span className="text-gray-800 text-base">
                            {isoCode && isoCode.toUpperCase()}.
                            {
                              beneficiary?.product_type?.intervals[interval][
                                isoCode
                              ]
                            }
                          </span>
                        ) : (
                          <span className="text-gray-600">
                            {isoCode && isoCode.toUpperCase()}.0
                          </span>
                        )}
                      </span>
                      <span className="ml-1">
                        <SelectInterval />
                      </span>
                    </td>
                    <td align="end">
                      <span className="pr-2">
                        <SelectCurrency hideFlag={true} />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        )}
        {/* RESPONSIVE PLANS */}
        {is_profile !== true && (
          <div className="w-full border border-gray-300 mt-6 mb-2 bg-white md:hidden">
            <div className="flex items-center bg-white border-b border-gray-300 min-h-50p px-5">
              <span className="text-gray-800 text-normal font-bold">
                Selecciona el plan
              </span>
            </div>
            <div className="text-gray-800 text-normal flex justify-between p-5 border-b border-gray-300">
              <span className="font-bold">Plan</span>
              <div className="flex flex-col items-end">
                {isArray(beneficiary.products) &&
                  beneficiary.products.map((product, i) => (
                    <span key={i} className="m-b5">
                      <FormControlLabel
                        key={i}
                        name="product"
                        value={String(product._id)}
                        control={
                          <Radio
                            value={String(product._id)}
                            className={
                              beneficiary.product_id === product._id
                                ? "checkBoxActive"
                                : "checkBox"
                            }
                            checked={
                              beneficiary.product_id === product._id
                                ? true
                                : false
                            }
                          />
                        }
                        onChange={() => handleSelectPlan(product, index)}
                        label={
                          <span
                            className={`text-gray-800 text-normal ${
                              beneficiary.product_id === product._id
                                ? "font-bold"
                                : "font-normal"
                            }`}
                          >
                            {product.name}
                          </span>
                        }
                        labelPlacement="start"
                        disabled={beneficiary.NotEditCustomer}
                      />
                    </span>
                  ))}
              </div>
            </div>
            <div className="text-gray-800 text-normal flex items-center justify-between px-5 min-h-50p">
              <span className="font-bold">Valor</span>
              <span>
                {beneficiary?.product_type &&
                beneficiary?.product_type.intervals ? (
                  <span className="text-gray-800 text-base">
                    {isoCode && isoCode.toUpperCase()}.
                    {beneficiary?.product_type?.intervals[interval][isoCode]}
                  </span>
                ) : (
                  <span className="text-gray-600">
                    {isoCode && isoCode.toUpperCase()}.0
                  </span>
                )}
                <span className="ml-4">
                  <SelectInterval />
                </span>
                <span className="ml-4">
                  <SelectCurrency hideFlag={true} />
                </span>
              </span>
            </div>
          </div>
        )}
      </div>
      <SnackBar
        openAlert={alert.openAlert}
        messageAlert={alert.messageAlert}
        handleClose={handleClose}
      />
    </div>
  );
};

export default BeneficiaryForm;

import React from 'react';

export const data = [
    {
        icon: '/images/partners/logo_venemergencia.svg',
        text: 'Logo venemergencia',
        name: 'logo_venemergencia.svg',
        styles: {
            width: 280,
        },
    },
    {
        icon: '/images/partners/logo_mercantil.svg',
        text: 'Logo mercantil seguros',
        name: 'logo_mercantil.svg',
        styles: {
            width: '75%',
            marginTop: '.9rem',
        },
    }
];

export const Title = () => (
    <h2 className="hidden md:block text-purple-500 text-center font-semibold text-lg leading-snug mb-4">
        Nuestros partners
    </h2>
);

export const PrimaryComponent = ({ item }) => (
    <>
        <style>
            {`
                #__next > div > div > div > div:nth-child(11) > div > div > ul {
                    align-items: center !important;
                }

                #__next > div > div > div > div:nth-child(10) > div:nth-child(3) > div {
                    padding-bottom: 5rem;
                }

                @media only screen and (min-width: 960px) and (max-width: 1279px) {
                    #__next > div > div > div > div:nth-child(11) > div > div > ul > li:nth-child(2) > div > div > img {
                        width: 65% !important;
                    }
                }
                @media only screen and (min-width: 1280px) and (max-width: 1520px) {
                    #__next > div > div > div > div:nth-child(11) > div > div > ul > li:nth-child(2) > div > div > img {
                        width: 65% !important;
                    }
                }
            `}
        </style>
        <div className="hidden md:flex px-5 justify-start items-center flex-col w-full mb-10">
            <div className="flex items-center justify-center pointer-events-none">
                <img name={item.name} src={item.icon} alt={item.text} className="object-cover" style={item.styles} />
            </div>
        </div>
    </>
);

// ${item.hasStyles ? classes.size : ''}
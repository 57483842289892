import React, { useState, useEffect, useRef } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import Cookies from "js-cookie";
import TagManager from "react-gtm-module";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import { LinearProgressBar } from "components/widgets";
import LayoutContainer from "components/layout/LayoutContainer";
import StickyButton from "components/home/StickyButton";
import useSticky from "components/hooks/useSticky";
import { FastQuotation } from "components/forms";
import StickyCall from "components/home/StickyCall";
// carousels children
import {
  data as indicatorData,
  Title as IndocatorTitle,
  PrimaryComponent as IndicatorComp,
  SecondaryComponent as SecIndicatorComp,
} from "components/home/childrensCarousel/indicator";
import {
  data as newsData,
  PrimaryComponent as NewsComp,
} from "components/home/childrensCarousel/news";
import {
  responsive as benefitsResponsive,
  data as benefitsData,
  Title as BenefitTitle,
  PrimaryComponent as BenefitComp,
  SecondaryComponent as SecBenefitComp,
} from "components/home/childrensCarousel/benefits";
import {
  responsive as testimonialsResponsive,
  data as testimonialsData,
  Title as TestimonialTitle,
  PrimaryComponent as TestimonialComp,
} from "components/home/childrensCarousel/testimonials";
import {
  data as partnersData,
  Title as PartnerTitle,
  PrimaryComponent as PartnerComp,
} from "components/home/childrensCarousel/partners";

const MainHeader = dynamic(
  () => import("components/home/mainHeader/MainHeader"),
  { loading: () => <LinearProgressBar showLogo={false} /> }
);
const Promo = dynamic(() => import("components/home/Promo"), {
  loading: () => <LinearProgressBar showLogo={false} />,
});
const CarouselContainer = dynamic(
  () => import("components/home/CarouselContainer"),
  { loading: () => <LinearProgressBar showLogo={false} /> }
);
const ContainerVideo = dynamic(
  () => import("components/widgets/ContainerVideo"),
  { loading: () => <LinearProgressBar showLogo={false} /> }
);

const serverUrlImages = process.env.REACT_APP_SERVER_URL_IMAGES;
const bgVideo = `${serverUrlImages}/RD/asistensi-republica-dominicana.png`;

const HomePage = () => {
  const router = useRouter();
  const canonicalURL =
    process.env.REACT_APP_DOMAIN_URL + (router?.asPath || router?.pathname);

  const { isSticky, element, cotizaElement } = useSticky();
  const [showVideo, setShowVideo] = useState(false);
  const [slide, setSlide] = useState(0);
  const [showButton, setShowButton] = useState(true);

  const promoElement = useRef(null);

  const handleVideo = (showV) => {
    setShowVideo(showV);
  };

  const goToHomeQuotation = () => {
    let dims =
      cotizaElement.current.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo(0, dims - 100);
    const event_quotation = {
      dataLayer: {
        event: "evento-boton-home-quiero-cotizar",
      },
    };
    TagManager.dataLayer(event_quotation);
  };

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: "pageview",
        pageTitle: "asistensi Venezuela | Seguros de emergencias médicas",
      },
    };
    TagManager.dataLayer(tagManagerArgs);

    if (location?.pathname) {
      if (router.asPath !== "/") {
        const { nick_promoter, coupon } = router.query;
        let coupon_promoter = "";
        if (nick_promoter) {
          if (coupon) coupon_promoter = coupon;
          const inCookie = Cookies.get("influencer");
          if (!inCookie)
            Cookies.set(
              "influencer",
              JSON.stringify({ nick_promoter, coupon_promoter }),
              { expires: 90 }
            );
        }
      }
    }
  }, []);

  return (
    <LayoutContainer>
      <style>
        {`
            .transform-text {
              transform: rotate(-90deg);
              left: -30px;
              bottom: 30%;
            }
            @media only screen and (min-width: 600px) and (max-width: 959px) {
              .transform-text {
                transform: none;
              }
            }
            @media only screen and (max-width: 599px) {
              .transform-text {
                transform: none;
              }
            }
            `}
      </style>
      <Head>
        <link rel="canonical" href={canonicalURL} />
      </Head>
      <div className="bg-gray-100 relative overflow-hidden">
        {showButton && (
          <StickyButton
            className="fixed md:hidden"
            isSticky={isSticky}
            onClick={goToHomeQuotation}
          />
        )}
        <StickyCall />
        <MainHeader element={element} goToHomeQuotation={goToHomeQuotation} />
      </div>

      {/* news carousel */}
      <CarouselContainer data={newsData} PrimaryComponent={NewsComp} isCenter />

      {/* indicators carousel */}
      <CarouselContainer
        data={indicatorData}
        Title={IndocatorTitle}
        PrimaryComponent={IndicatorComp}
        SecondaryComponent={SecIndicatorComp}
      />

      <div
        id="cotizacion"
        className="bg-white container py-20"
        ref={cotizaElement}
      >
        <FastQuotation origin="Cotización" fromHome={true} />
      </div>

      <div ref={promoElement}>
        <Promo
          goToHomeQuotation={goToHomeQuotation}
          slide={slide}
          setSlide={setSlide}
        />
      </div>

      <div className="py-20 container flex flex-col relative lg:flex-row items-center">
        <div className="w-full flex flex-col lg:order-2 lg:w-1/2 lg:ml-8">
          <h2 className="text-4xl text-purple-500 font-poppins font-bold">
            ¿Qué es{" "}
            <span className="text-pink-500 font-poppins font-bold">
              asistensi
            </span>
            ?
          </h2>
          <p className="text-gray-800 leading-relaxed text-base md:text-lg xl:text-xl mt-4">
            Somos una compañía digital que ofrece servicios de salud accesibles que protegen frente a los malestares del día a día, enfermedades agudas y emergencias médicas.
          </p>
          <p className="text-gray-800 leading-relaxed text-base md:text-lg xl:text-xl mt-4">
            Estamos enfocados en apoyar principalmente a quienes han emigrado para que puedan seguir cuidando de sus seres queridos en sus países de origen sin importar las distancias.
          </p>
        </div>

        <div className="rounded-2xl flex justify-center items-center w-full h-auto relative overflow-hidden mt-16 lg:mt-0 lg:w-1/2">
          <div className="w-full h-auto">
            <img
              loading="lazy"
              name="asistensi-venezuela.png"
              alt="¿Qué es asistensi Venezuela?"
              src={bgVideo}
              className="w-full"
            />
          </div>
          <div className="cursor-pointer absolute bg-white p-5 rounded-full">
            <PlayArrowIcon
              className="text-pink-500 sm-icon"
              onClick={() => handleVideo(true)}
            />
          </div>
        </div>

        { showVideo === true && <ContainerVideo src="https://player.vimeo.com/video/672963073" handleClose={handleVideo}/> }
      </div>

      {/* benefits carousel */}
      <CarouselContainer
        responsive={benefitsResponsive}
        data={benefitsData}
        Title={BenefitTitle}
        PrimaryComponent={BenefitComp}
        SecondaryComponent={SecBenefitComp}
      />

      {/* testimonials carousel */}
      <CarouselContainer
        responsive={testimonialsResponsive}
        data={testimonialsData}
        Title={TestimonialTitle}
        PrimaryComponent={TestimonialComp}
        isContainer={false}
        showDots
        dotListClass="dot-testimonials"
        arrows={false}
      />

      {/* partners carousel */}
      <CarouselContainer
        data={partnersData}
        Title={PartnerTitle}
        PrimaryComponent={PartnerComp}
        isCenter
        isContainer={false}
      />
    </LayoutContainer>
  );
};

export default HomePage;

export const paymentMethod = (method) => {
  if (method === 'E') {
    return 'Efectivo';
  } else if (method === 'C') {
    return 'Cheque';
  } else if (method === 'Z') {
    return 'Zelle';
  } else if (method === 'S' || method === 'B') {
    return 'Tarjeta de Crédito';
  } else if (method === 'T') {
    return 'Transferencia';
  } else if (method === 'P') {
    return 'PayPal'
  }
}

export const paymentStatus = (status, chip = false) => {
  if (status === 'pending') {
    if(chip) return 'Por aprobar';
    return 'Pendiente por aprobación';
  } else if (status === 'succeeded') {
    return 'Exitoso';
  } else if (status === 'active') {
    return 'Activo';
  } else if (status === 'denied') {
    return 'Denegado';
  } else if (status === 'canceled') {
    return 'Cancelado';
  } else if (status === 'annulled' || status === 'expired') {
    return 'Anulado';
  } else if (status === 'trialing') {
    return 'Prueba gratuita';
  } else if (status === 'free_trial') {
    return 'Free Trial';
  } else if (status === 'unpaid') {
    return 'Por pagar';
  } else if(status === 'failed_payment') {
    return 'Pago fallido'
  } else if (status === 'suspended') {
    return 'Suspendido'
  } else if (status === 'not-complete') {
    return 'Por completar'
  } else {
    return '';
  }
}

export const paymentInterval = (param) => {
  if (param === 'month') {
    return 'Mensual'
  } else if (param === 'year') {
    return 'Anual'
  } else if (param === 'week') {
    return 'Semanal'
  } else if (param === 'day') {
    return 'Diario'
  } else if (param === 'bimonthly') {
    return 'Bimestral'
  } else if (param === 'quarterly') {
    return 'Trimestral'
  } else if (param === 'semiannual') {
    return 'Semestral'
  } else {
    return ''
  }
}

export const paymentStatusInvoice = (status) => {
  if (status === 'paid') {
    return 'Pagado';
  } else if (status === 'draft') {
    return 'Próximo pago'
  } else if (status === 'open') {
    return 'Por pagar'
  } else if (status === 'uncollectible') {
    return 'Factura Incobrable'
  } else if (status === 'void') {
    return 'Devolución'
  } else {
    return ''
  }
}

export const chipPaymentStatus = (status) => {
  return (
    <>
      {
        (status === 'succeeded' || status === 'active') && 
        <span className="text-xs bg-green-100 text-green-200 py-2 px-3 rounded-full">
          {paymentStatus(status, true)}
        </span>
      }
      {
        (status === 'pending' || status === 'not-complete') && 
        <span className="text-xs bg-purple-200 text-purple-500 py-2 px-3 rounded-full">
          {paymentStatus(status, true)}
        </span>
      }
      {
        (status === 'trialing' || status === 'free_trial' || status === 'unpaid') && 
        <span className="text-xs bg-pink-200 text-pink-500 py-2 px-3 rounded-full">
          {paymentStatus(status, true)}
        </span>
      }
      {
        (status === 'denied' || status === 'canceled' || status === 'annulled' || status === 'failed_payment' || status === 'expired') && 
        <span className="text-xs bg-gray-300 text-gray-800 py-2 px-3 rounded-full">
          {paymentStatus(status, true)}
        </span>
      }
    </>
  )
}